<div class="spinner {$$props.class}" />

<style>
	.spinner {
		display: inline-block;
		aspect-ratio: 1;
	}
	.spinner:after {
		content: " ";
		display: block;
		height: 80%;
		aspect-ratio: 1;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid var(--textcolor);
		border-color: var(--textcolor) transparent var(--textcolor) transparent;
		animation: spinner 1.2s linear infinite;
	}
	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
