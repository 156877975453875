<script lang="ts">
	export let data: Feature;
	export let onUpdate: () => void;
</script>

<label for={data.id}>
	<h2>{data.id}</h2>
	<div>
		<input
			type="checkbox"
			id={data.id}
			name={data.id}
			on:click={onUpdate}
			bind:checked={data.enabled}
			disabled={!data.available}
		/>
		<span>{data.description}</span>
	</div>
</label>

<style>
	h2 {
		font-size: 1.2rem;
		font-weight: bold;
	}
</style>
