<script lang="ts">
	if (window.opener && window.opener !== window) {
		window.close();
	}
	import SpinnerComponent from "./Spinner.svelte";
	import FeatureComponent from "./Feature.svelte";

	let user: string;

	let featuresPromise: Promise<FeaturesResponse>;
	let features: Feature[];
	let postFeaturesButton: HTMLButtonElement;

	async function getFeatures() {
		const response = await fetch("/features");
		if (![200, 401, 403].includes(response.status)) {
			throw new Error(`Unexpected response status: ${response.status}`);
		}

		if ([401, 403].includes(response.status)) {
			throw await response.json();
		}

		const features = (await response.json()) as FeaturesResponse;
		return features;
	}
	featuresPromise = getFeatures();

	$: featuresPromise.then((response) => {
		console.log("Updated features");
		user = response.user;
		features = response.features;
	});

	async function openPopup(url: string) {
		const width = 500;
		const height = 800;
		const xPos = window.screenX + window.outerWidth / 2;
		const yPos = window.screenY + window.outerHeight / 2 - height / 2;
		const popup = window.open(
			url,
			"Mother Mouse Twitch Auth",
			`width=${width},height=${height},resizable=yes,scrollbars=yes,location=yes,menubar=no,left=${xPos},top=${yPos}`
		);
		if (!popup) {
			location.href = url;
			return;
		}

		await new Promise((resolve) => {
			setInterval(() => popup.closed && resolve(null), 200);
		});
		featuresPromise = getFeatures();
	}

	async function postFeatures() {
		const enabledFeatureIds = features.filter((f) => f.enabled).map<string>((f) => f.id);
		console.log(enabledFeatureIds);
		const res = await fetch("/features", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(enabledFeatureIds),
		});
		if (res.status === 500) {
			alert("Error: " + (await res.text()));
			featuresPromise = getFeatures();
		} else {
			postFeaturesButton.disabled = true;
		}
	}
</script>

<main class="text-center">
	<h1 class="text-2xl font-bold bg-indigo-200 mb-3">{user ? `Hello, ${user}!` : "Not logged in"}</h1>

	{#await featuresPromise}
		<SpinnerComponent />
	{:then response}
		<ul>
			{#each response.features as feature}
				<li><FeatureComponent bind:data={feature} onUpdate={() => (postFeaturesButton.disabled = false)} /></li>
			{/each}
		</ul>
		<button bind:this={postFeaturesButton} on:click={postFeatures} disabled={true}>Update Features</button>
	{:catch error}
		<h2>{error.message}</h2>
		<button class="twitch" on:click={() => openPopup(error.authUrl)}>Login with Twitch</button>
	{/await}
</main>

<style lang="postcss" global>
	@tailwind base;
	@tailwind components;
	@tailwind utilities;

	:root {
		--twitch: #9146ff;
		--textcolor: #000;
	}

	@keyframes twitch {
		10% {
			transform: rotate(5deg);
		}
		30% {
			transform: rotate(-5deg);
		}
		50% {
			transform: rotate(5deg);
		}
		70% {
			transform: rotate(-5deg);
		}
		90% {
			transform: rotate(5deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}

	.twitch {
		padding: 10px;
		color: #f0f0ff;
		text-align: center;
		text-decoration: none;
		background-color: var(--twitch);
		animation: twitch 500ms normal;
	}

	ul {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-bottom: 1rem;
		max-width: 600px;
		gap: 20px;
	}
</style>
